@import '../../assets/styles/variables';

.navigation {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  position: fixed;
  width: 280px;
  max-width: 336px;
  height: calc(100% - 64px);
  box-sizing: border-box;
  bottom: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  background-color: white;
  z-index: 9;
}
@media (max-width: 959px) {
  .navigation {
    display: none;
  }
}

.links {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 262px);
  overflow-y: auto;
  overflow-x: hidden;
}

.userMenu {
  border-bottom: 1px solid #e8e7e7;
  margin: 0 -16px 8px;
  padding: 0px 16px 8px;
}

.avatarInitials {
  font-size: small;
  letter-spacing: -1.5px;
}

.avatar {
  background-color: $backgroundColorPrimaryHeader;
  width: 24px;
  height: 24px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 16px;
  text-decoration: none;
  color: $colorTextSecondary;
  cursor: pointer;
  gap: 8px;
  line-height: 1.2;
  box-sizing: border-box;
  width: 240px;

  &.menuItemActive {
    font-weight: bold;
    background-color: $navHoverBackgroundColor;
    border-radius: 7px;
    letter-spacing: normal;
    height: 48px;
  }

  &:hover {
    background-color: $navHoverBackgroundColor;
    border-radius: 7px;
  }

  svg {
    color: $backgroundButton1;
  }
}

.back {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 16px;
  text-decoration: none;
  color: $colorTextSecondary;
  cursor: pointer;
  svg {
    margin-right: 4px;
    width: 32px;
    height: 32px;
    color: $backgroundButton1;
  }
}

.footer {
  border-top: 1px solid #e8e7e7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  background: #fff;

  .footerMainItems {
    .footerItem {
      cursor: pointer;
      height: 48px;
      width: 240px;
      &:hover {
        background-color: #f0f0f0;
        border-radius: 7px;
      }
    }
  }

  .footerBottomItems {
    margin-top: auto;
    text-align: center;

    .footerItem {
      padding: 0 20px;
      font-size: 12px;

      &:last-child {
        padding-top: 5px;
      }
    }
  }

  .footerItem,
  .footerItemActive {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 16px;
    text-decoration: none;
    color: $colorTextSecondary;
    gap: 8px;
    box-sizing: border-box;

    a,
    div {
      text-decoration: none;
      color: #485c6e;
    }
    a,
    input {
      cursor: pointer;
    }

    svg {
      color: $backgroundButton1;
    }

    &.footerItemActive {
      font-weight: bold;
      background-color: #f0f0f0;
      border-radius: 7px;
      letter-spacing: normal;
      height: 48px;
    }
  }
}

@media (max-width: 959px) {
  .sidebar {
    display: none;
  }
}
