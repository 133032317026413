@import '../../../assets/styles/variables';

.content {
  width: calc(100% - 280px);
  @media (max-width: 959px) {
    width: 100%;
  }
}

.contentWithSidebar {
  width: calc(100% - 280px);
}
@media (max-width: 959px) {
  .contentWithSidebar {
    width: 100%;
  }
}

.publicContent {
  width: 100%;
}

.publicContentWithSidebar {
  width: calc(100% - 280px);
}
@media (max-width: 959px) {
  .publicContentWithSidebar {
    width: 100%;
  }
}
