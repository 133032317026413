@import '../../../assets/styles/variables';

.navbarFooter {
  border-top: 1px solid #e8e7e7;
  padding: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.MobileFooter {
    padding: 12px 20px 12px 20px;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }
}

.navbarFooterItem {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 240px;
  height: 48px;
  padding: 0 10px;
  border-radius: 7px;
  font-size: 10px;
  text-decoration: none;
  color: $colorText1;
  text-align: unset;
  cursor: pointer;

  &.terms {
    span {
      display: flex;
      align-items: center;
      font-size: 12px !important;
    }
  }

  &:hover {
    font-weight: bold;
    background-color: #f0f0f0;
    border-radius: 3px;
  }

  a {
    font-size: 16px;
    text-decoration: none;
    color: $colorText1;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  // .languageSelect {
  //   input {
  //     min-width: 3.5rem;
  //     border-color: #e8e7e7;
  //     color: $colorTextSecondary;
  //   }
  // }
}

.navbarFooterItemActive {
  font-weight: bold;
  background-color: #f0f0f0;
  border-radius: 3px;
}

.FooterIcon {
  all: unset;
  color: #485c6e;
}

.TEST {
  display: flex;
  flex-direction: column;
}

.menuFooterTextMobile {
  font-size: 13px;
  cursor: pointer;
  color: #485c6e;
}

.menuFooterTextDesktop {
  font-size: 16px;
  color: #485c6e;
  cursor: pointer;
}

.TermsPrivacyWrapper {
  display: flex;
  align-items: center;
  border-radius: 3px;
  gap: 7px;
  width: 240px;
  height: 24px;
}

.copyRightWrapper {
  width: 240px;
  height: 12px;
}

.TermsAndCopyText {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.8571429252624512px;
  text-align: left;
  color: #485c6e;
}

.InputBase {
  color: #485c6e;
  cursor: pointer;

  &.InputBaseMobile {
    font-size: 13px;
  }
  &.InputDesktop {
    font-size: 16px;
  }
}
