@import '../assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5.5rem auto 0;
  width: 51rem;
}

.title {
  font-size: 2.06rem;
}

.content {
  padding: 4rem 5rem;
  background-color: #fff;

  h1 {
    margin: 40px 0 8px;
    font-size: 18px;
    line-height: 1.39;
    font-weight: 400;
    color: $colorTextSecondary;
  }

  p {
    font-size: 13px;
    line-height: 1.92;
    color: $colorTextSecondary;
  }
}

@media(max-width: 959px) {
  .container {
    margin-top: 4rem;
    width: 90%;
  }

  .title {
    font-size: 1.75rem;
  }

  .content {
    padding: 1.5rem;

    p {
      font-size: 1rem;
    }
  }
}