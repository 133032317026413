@import '../../assets/styles/variables';

.navbarMenu {
  background-color: $colorPaper;
}

header {
  position: fixed;
  z-index: 1200;
}

.navbarWrapper {
  z-index: 9999;
  display: flex;
  position: fixed;
  top: 64px;
  justify-content: space-between;
  width: 100%;

  &.RegistrationPage {
    top: 100px;
  }

  @media (max-width: 959px) {
    justify-content: center;
    top: 60px;

    &.RegistrationPage {
      top: 60px;
    }
  }
}

.innerDiv {
  width: 10px;
  visibility: hidden;

  @media (max-width: 959px) {
    display: none;
  }
}

.navbarMenu.mainbar {
  z-index: 9999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 16px 12px 16px;
}
.navbarMenu.mainbarSignup {
  z-index: 9999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.navbarMenu.mobileDropdown {
  min-width: 100%;
}

.navbarMenu {
  display: flex;
  flex-direction: column;
  width: 272px;
  height: 100%;

  .navbarMenuItem {
    // padding: 12px 16px 12px 16px;
    // text-align: unset;
    display: flex;
    gap: 2px;
    color: $colorText1 !important;
  }
}

.menuContainer {
  height: 100%;
}

.businessBtn {
  background-color: #00799b;
  color: #fefefe;
  height: 2.25rem;
  width: 9.688rem;
  border-radius: 7px;

  .businessIcon {
    margin-bottom: 2px;
  }
  .businessText {
    margin-top: 2px;
  }

  &:hover {
    background-color: #134e5f;
  }
}

.logo {
  text-decoration: none;
}

.logoImage {
  height: 40px;
  width: 40px;
}

.searchBarMobile {
  justify-content: space-between;
}

.searchBarDesktop {
  justify-content: start;
}

.logoContainer {
  display: flex;
  align-items: center;
  z-index: 2;
  flex-grow: 1;

  .search {
    height: 40px;
    width: 400px;

    .searchInput > div {
      background-color: $backgroundColorPrimaryHeader;
      border: 0;
      border-radius: 33px;
      height: 40px;
      svg {
        font-size: 24px;
      }
    }

    .selectedSearchInput > div {
      color: #5c6268;
      font-family: 'Roboto', arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.857px;
      height: 40px;
      background-color: white;
      border: 1px solid rgba(72, 92, 110, 0.25);
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      border-bottom: 2px solid #00799b;
      svg {
        font-size: 24px;
      }
    }

    .searchInput input {
      color: #5c6268;
      font-family: 'Roboto', arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.857px;
    }
  }
}

.mobileSearch {
  position: absolute;
  top: 65px;
  left: 0;
  .searchInput {
    width: 100vw;
    height: 59px;
    background-color: #fff;
  }
}

.btnGrid {
  width: 100vw;
  background-color: white;
  text-align: center;
  button {
    all: unset;
    color: #485c6e;
    border: 0.5px solid #e8e7e7;
    width: 50vw;
    height: 54px;
    border-radius: 0%;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.86;
  }
}

.rightGroup {
  display: flex;
  align-items: center;
  width: 200px;
  z-index: 3;
  gap: 10px;
  flex-grow: 1;
  justify-content: end;

  svg {
    font-size: 24px;
  }
}

.avatarInitials {
  font-size: small;
  letter-spacing: -1.5px;
}

.logoGroup {
  display: flex;
  gap: 10px;
}

.userName {
  font-family: $fontBold;
  font-size: 12px;
  color: $colorPaper;
}

.dropOptionsBtn {
  width: 240px;
  height: 48px;
  color: #485c6e;
}

.logoAvatar {
  width: 40px;
  height: 40px;
  font-size: 15px;
  background-color: $backgroundColorPrimaryHeader;
  letter-spacing: -1.5px;
}

.logoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $colorTextSecondary;

  .logoBrand {
    letter-spacing: 4px;
  }

  .logoTitle {
    font-size: 14px;
    letter-spacing: 1.5px;
    color: $colorPrimaryMain;
  }
}

@media (max-width: 959px) {
  .logoContainer {
    width: auto;
    flex-grow: 1;
    gap: 0.5rem;

    .search {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .logoText {
    font-size: 0.7rem;
    padding-left: 0.7rem;
    letter-spacing: 0.2rem;
  }

  .logoImage,
  .logoAvatar {
    width: 28px;
    height: 28px;
    font-size: 14px;
  }

  .logoAvatarMobileDrop {
    width: 24px;
    height: 24px;
  }

  .logoAvatar svg {
    font-size: 16.8px;
  }

  .navbarMenu.mainbar {
    left: 0;
    width: 100%;
    padding: 12px 16px 12px 16px;
    margin-top: 4px;

    & > li:last-child {
      display: flex;
      align-items: center;
    }
    .navbarMenuItem {
      min-height: auto !important;
      // background-color: black;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .navbarMenu.mainbarSignup {
    left: 0;
    width: 100%;
  }

  .userName {
    margin-right: 5px;
  }
}

.MobileMenuHeader {
  border-bottom: 1px solid #e8e7e7;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 240px;
  height: 48px;
  padding: 0;
  text-decoration: none;
}

.LinksWrapper {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px 21px;

  &.SecondSection {
    height: auto;
  }
}

.IconStyle {
  all: unset;
  color: #485c6e;
  width: 24px;
  height: 24px;
}

.mobileMenuAvatar {
  display: flex;
  gap: 3px;
  align-items: center;
}

.menuText {
  font-size: 13px;
  color: #485c6e;

  &.menuDesktopText {
    font-size: 16px;
  }
}

.logOutBtn {
  display: flex;
  width: 240px;
  height: 48px;
  gap: 8px;
  padding: 0;

  &.logOutDesktop {
    width: 272px;
    height: 48px;
    padding: 10px;
    margin-left: 0px;
    border-radius: 3px;
  }
}

.AvatarWrapper {
  width: 39.7px;
}

.SelectedIcon {
  color: $colorPrimaryMain;
}

.SelectedMenuText {
  font-weight: 600;
}

.IconStyleBackRoute {
  all: unset;
  color: #485c6e;
}

.backIcon {
  margin-top: 6px;
}

.BorderWrapper {
  padding: 8px 0px 16px 0px;
}

.Border {
  border: none;
  border-bottom: 1px solid #e8e7e7;
  margin: 0;
}

.navbarMenuDesktop {
  display: flex;
  gap: 8px;
  padding: 10px;
}

.NavDesktopLinks {
  padding-bottom: 200px;
}
