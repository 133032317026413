@import '../../assets/styles/variables';

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  /* padding: 1.25rem; */
  width: 100%;
  max-width: 48rem;
}
@media(max-width: 1023px) {
  .container {
    max-width: 75%;
  }
}
@media(max-width: 959px) {
  .container {
    /* margin-bottom: 5rem; */
    width: auto;
  }
}

.link {
  margin: 0 0.25rem;
  font-size: 0.625rem;
  color: $colorText1;
}

.title {
  margin-left: 0.25rem;
  font-size: 0.625rem;
  color: $colorText1;
}
